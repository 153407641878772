import React from "react";
import styled from "styled-components";
import pocketGod from "../assets/images/godz/animated/white-waiting.gif";
import LinkButton from "./components/Buttons/LinkButton";
import { Helmet } from "react-helmet-async";

const LinksContainer = styled.section`
  img {
    max-height: 20vh;
    margin: 0 auto 1rem auto;
  }
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 2rem auto;
  padding: 1rem 2rem;
  h2 {
    font-size: 1rem;
    font-family: Arial;
    margin-bottom: 2rem;
  }
`;

const LinksList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const LinkItem = styled.li`
  display: block;
  width: 100%;
`;

const TimeAgoStyle = styled.span`
  font-family: Arial, Helvetica, sans-serif;
  color: var(--color-success);
`;

const MintText = styled.p`
  margin: 0 0 1rem 0;
`;

const Links = () => {
  return (
    <LinksContainer>
      <Helmet>
        <title>Official Links | Pocket Godz</title>
      </Helmet>
      <img src={pocketGod} alt="Pocket Godz Character" />
      <h1>Pocket Godz</h1>
      <h2>Official Links</h2>
      <MintText>
        <TimeAgoStyle>ALPHA version out now!</TimeAgoStyle>
      </MintText>
      <LinksList>
        <LinkItem>
          <LinkButton href="https://play.pocketgodz.xyz/" highlighted>
            Play ALPHA version
          </LinkButton>
        </LinkItem>
        <LinkItem>
          <LinkButton href="https://magiceden.io/marketplace/pocket_godz">
            BUY ON MAGIC EDEN
          </LinkButton>
        </LinkItem>
        <LinkItem>
          <LinkButton href="/" internal={true}>
            Website
          </LinkButton>
        </LinkItem>
        <LinkItem>
          <LinkButton href="https://staking.pocketgodz.xyz">
            PGZ Staking
          </LinkButton>
        </LinkItem>
        <LinkItem>
          <LinkButton href="https://discord.gg/bN2KzaTNeS">Discord</LinkButton>
        </LinkItem>
        <LinkItem>
          <LinkButton href="https://twitter.com/pocketgodz">Twitter</LinkButton>
        </LinkItem>
        <LinkItem>
          <LinkButton href="https://www.instagram.com/pocketgodz/">
            Instagram
          </LinkButton>
        </LinkItem>
      </LinksList>
    </LinksContainer>
  );
};

export default Links;
