import React from "react";
import { Helmet } from "react-helmet-async";

import HeroSection from "./components/HeroSection";
import AppShell from "./AppShell";

const Home = () => {
  return (
    <AppShell>
      <Helmet>
        <title>Home | Pocket Godz</title>
      </Helmet>
      <HeroSection />
    </AppShell>
  );
};

export default Home;
