import React from "react";
import styled from "styled-components";
import Check from "./AnimatedCheckmark/Check";
import Cross from "./AnimatedCross/Cross";
import ReactTimeAgo from "react-time-ago";

const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  min-height: 10vh;
  padding: 1rem;
  button {
    color: white;
    padding: 1.5rem 3rem;
    position: relative;
    &:hover {
      color: black;
      background-color: white;
      cursor: pointer;
    }
  }
  &.success {
    border: 1px solid var(--color-success);
  }

  &.error {
    border: 1px solid var(--color-error);
  }
`;

const Whitelisted = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  p {
    font-size: 0.9rem;
    margin-bottom: 0;
  }
  @media (min-width: 992px) {
    h2 {
      font-size: 1.25rem;
      margin-bottom: 5px;
    }
    p {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
`;
const NotWhitelisted = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  p {
    font-size: 0.9rem;
    margin-bottom: 0;
  }
  @media (min-width: 992px) {
    h2 {
      font-size: 1.25rem;
      margin-bottom: 5px;
    }
    p {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
`;

const Dialog = styled.div``;

const Message = styled.div`
  text-align: left;
  align-items: center;
`;

const TimeAgoStyle = styled.span`
  font-family: Arial, Helvetica, sans-serif;
  color: var(--color-success);
`;

const VerifyPopup = (props: any) => {
  const MINT_DATE = new Date("2022-02-16T21:30:00.000Z");

  return (
    <Dialog>
      <Container
        className={
          props.whitelisted !== null
            ? props.whitelisted
              ? "success"
              : "error"
            : ""
        }
      >
        {props.whitelisted !== null ? (
          props.whitelisted ? (
            <Whitelisted>
              <Check smallMobile />
              <Message>
                <h2>All set.</h2>
                <p>
                  This wallet is ready for Pocket Godz whitelist mint{" "}
                  <TimeAgoStyle>
                    <ReactTimeAgo date={MINT_DATE} locale="en-US" />
                  </TimeAgoStyle>{" "}
                  (Feb 16 21:30-22:00 UTC).
                </p>
              </Message>
            </Whitelisted>
          ) : (
            <NotWhitelisted>
              <Cross smallMobile />
              <Message>
                <h2>This wallet is NOT whitelisted.</h2>
                <p>
                  If you believe that this is incorrect, please visit the{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://discord.com/channels/920461895729815604/920942084989517854"
                  >
                    Support channel in our Discord and create a new support
                    ticket
                  </a>
                  .
                </p>
              </Message>
            </NotWhitelisted>
          )
        ) : null}
      </Container>
    </Dialog>
  );
};

export default VerifyPopup;
