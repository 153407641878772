import React from "react";
import Home from "./pages/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LinksPage from "./pages/Links";
import Verify from "./pages/Verify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import TheGamePage from "./pages/TheGame";
import RoadmapPage from "./pages/Roadmap";
import FAQ from "./pages/FAQ";
import AboutPage from "./pages/About";
import Whitepaper from "./pages/components/Whitepaper/Whitepaper";

const App = () => {
  return (
    <Router>
      <HelmetProvider>
        <Helmet>
          <title>Home | Pocket Godz</title>
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/the-game" element={<TheGamePage />} />
          <Route path="/roadmap" element={<RoadmapPage />} />
          <Route path="/links" element={<LinksPage />} />
          <Route path="/wl-verify" element={<Verify />} />.
          <Route path="/whitepaper" element={<Whitepaper />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </HelmetProvider>
    </Router>
  );
};

export default App;
