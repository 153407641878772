import React from "react";
import { Helmet } from "react-helmet-async";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import styled from "styled-components";
import AppShell from "./AppShell";

const Section = styled.section`
  padding: 80px 0 0px;

  h2 {
    font-size: 48px;
    margin-bottom: 30px;
    @media (min-width: 992px) {
      text-align: center;
      margin-bottom: 0;
    }
  }

  @media (min-width: 480px) {
    h2 {
      font-size: 64px;
    }
  }

  @media (min-width: 992px) {
    padding: 100px 0 0;

    h2 {
      font-size: 64px;
    }
  }
`;

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ul.a {
    list-style-type: circle;
    margin-left: 30px;
    margin-top: 0;
  }
  ul.b {
    list-style-type: square;
    margin-left: 20px;
  }

  ul {
    margin-top: 1rem;
    li {
      font-family: "Archivo", sans-serif;
      font-size: 1rem;
      letter-spacing: 0.06em;
      line-height: 1.4em;
    }
  }
`;

const Roadmap = () => {
  return (
    <AppShell>
      <Helmet>
        <title>Roadmap | Pocket Godz</title>
      </Helmet>
      <Section id="roadmap">
        <h2>Roadmap</h2>
        <Container>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(33, 37, 41)", color: "#fff" }}
              contentArrowStyle={{ borderRight: "6px solid  rgb(33, 37, 41)" }}
              iconStyle={{ background: "rgb(149, 213, 178)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">Phase 1 </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Launch Protocols
              </h4>
              <ul className="b">
                <li>
                  Open social channels, release website, roadmap & whitepaper
                </li>
                <li>
                  Release of Pocket Godz lore & our mission to support 1/1 art
                  on Solana
                </li>
                <li>Presale to OGs & Partner communities </li>
                <li>Public mint</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              contentStyle={{ background: "rgb(33, 37, 41)", color: "#fff" }}
              contentArrowStyle={{ borderRight: "6px solid  rgb(33, 37, 41)" }}
              iconStyle={{ background: "rgb(255, 77, 109", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">Phase 2</h3>
              <h4 className="vertical-timeline-element-subtitle">
                Community & Resource Build Up
              </h4>

              <ul className="b">
                <li>
                  Verified channels open for Pocket Godz holders. Collaboration
                  with community on game development begins
                </li>
                <li>
                  Twitter spaces interviews and deeper collaborations begin with
                  1/1 artists
                </li>
                <li>
                  First community vote on 1/1 art to store in the Pocket Godz
                  Vault.
                </li>
                <li>
                  Expand website to include onboarding information & resources
                  for new artists entering Solana
                </li>
                <li>Further previews of game development released</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              contentStyle={{ background: "rgb(33, 37, 41)", color: "#fff" }}
              contentArrowStyle={{ borderRight: "6px solid  rgb(33, 37, 41)" }}
              iconStyle={{ background: "rgb(	255, 77, 109)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">Phase 3</h3>
              <h4 className="vertical-timeline-element-subtitle">Gaming</h4>

              <ul className="b">
                <li>Release of the PocketGodz fighter game</li>
                <ul className="a">
                  <li>
                    Beta release to holders first and then opened to the entire
                    Solana community
                  </li>
                </ul>
                <li>
                  Pocket Godz Fighter game will be a classic 1v1 fight game with
                  controls that many of you OG gamers will be familiar with.
                </li>
                <li>Host first official PG tournament</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              contentStyle={{ background: "rgb(33, 37, 41)", color: "#fff" }}
              contentArrowStyle={{ borderRight: "6px solid  rgb(33, 37, 41)" }}
              iconStyle={{ background: "rgb(	255, 77, 109)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">Phase 4</h3>
              <h4 className="vertical-timeline-element-subtitle">
                More to come..
              </h4>

              <ul className="b">
                <li>
                  Collaborate with PG community to develop and release $Godz
                  token
                </li>
                <li>
                  Enable partner communities to run white-listed tournaments on
                  Pocket Godz platform
                </li>
                <li>Collaborate with Twitch Streamers</li>
              </ul>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </Container>
      </Section>
    </AppShell>
  );
};
export default Roadmap;
