import React from "react";
import { useState } from "react";
import styled from "styled-components";

const Button = styled.button`
  cursor: pointer;
  color: var(--color-text-dark);
  position: fixed;
  bottom: 2.5%;
  right: 5%;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
  background: white;
  z-index: 5;
  border: none;
  box-shadow: 0px 0px 5px 0px white;
  opacity: 0;
  transition: opacity linear 0.1s;
  &.visible {
    opacity: 1;
  }
  @media (min-width: 992px) {
    bottom: 5%;
  }
  :hover,
  :active {
    background-color: var(--color-hover);
  }
`;

const Chevron = styled.span`
  &:before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: "";
    display: inline-block;
    height: 15px;
    position: relative;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 15px;
    color: var(--text-color-dark);
    top: 5px;
  }
`;

const ScrollToTop = () => {
  let lastKnownScrollPosition = 0;
  let ticking = false;
  const [visible, isVisible] = useState(false);
  const BUFFER = 750;

  document.addEventListener("scroll", function (e) {
    lastKnownScrollPosition = window.scrollY;
    if (!ticking) {
      window.requestAnimationFrame(function () {
        if (visible) {
          if (lastKnownScrollPosition < BUFFER) {
            isVisible(false);
          }
        } else {
          if (lastKnownScrollPosition > BUFFER) {
            isVisible(true);
          }
        }
        ticking = false;
      });
      ticking = true;
    }
  });
  const scrollTop = (e: any) => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <Button onClick={scrollTop} className={visible ? "visible" : ""}>
      <Chevron />
    </Button>
  );
};

export default ScrollToTop;
