import React from "react";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import styled from "styled-components";
import wl from "../../data/wl";

const Container = styled.div`
  margin: 1rem 0 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
  @media (min-width: 992px) {
    gap: 10px;
  }
`;
const Bar = styled.div`
  width: 100%;
  height: 30px;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
`;

const InternalBar = styled.div`
  position: absolute;
  transition: all ease-in-out 1.5s;
  width: 0%;
  height: 100%;
  background-color: #14f195;
  background-color: ${(props) => props.barColour()};
`;
const Figure = styled.div`
  color: var(--color-text-dark);
  font-family: Arial;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  width: 100%;
  z-index: 0;
`;

const WLBar = () => {
  const WL_MAX = 2369;
  const WL_CURRENT = wl.data.length;
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    setPercentage((WL_CURRENT / WL_MAX) * 100);
  }, [setPercentage, WL_CURRENT]);
  function getBarColour() {
    let ratio = percentage / 100;
    var color1 = "f74444";
    var color2 = "fff700";
    var hex = function (x) {
      x = x.toString(16);
      return x.length === 1 ? "0" + x : x;
    };

    var r = Math.ceil(
      parseInt(color1.substring(0, 2), 16) * ratio +
        parseInt(color2.substring(0, 2), 16) * (1 - ratio)
    );
    var g = Math.ceil(
      parseInt(color1.substring(2, 4), 16) * ratio +
        parseInt(color2.substring(2, 4), 16) * (1 - ratio)
    );
    var b = Math.ceil(
      parseInt(color1.substring(4, 6), 16) * ratio +
        parseInt(color2.substring(4, 6), 16) * (1 - ratio)
    );
    return "#" + hex(r) + hex(g) + hex(b);
  }
  return (
    <Container>
      <Bar>
        <InternalBar
          style={{ width: percentage + "%" }}
          barColour={getBarColour}
        ></InternalBar>
        <Figure>
          <CountUp end={percentage} duration={1.5} decimals={2} />% WL FULL
        </Figure>
      </Bar>
    </Container>
  );
};

export default WLBar;
