import React, { useState } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { HiLink, HiCheck } from "react-icons/hi";
const CopyLink = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 75px;
  p {
    font-size: 0.5rem !important;
    @media (min-width: 700px) {
      font-size: 1rem;
    }
    margin: 0;
    padding: 0;
  }
`;
const CopyAnchor = (props) => {
  const [copied, setCopied] = useState(false);
  const copyURL = (id) => {
    let location = window.location;
    return `${
      location.protocol +
      "//" +
      location.host +
      location.pathname +
      (location.search ? location.search : "")
    }#${id}`;
  };

  return (
    <CopyToClipboard
      text={copyURL(props.anchor)}
      onCopy={() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      }}
    >
      <CopyLink>
        {copied ? (
          <>
            {" "}
            <HiCheck
              size="1.5rem"
              style={{ color: "var(--color-highlight)" }}
            />
            <p>Copied</p>
          </>
        ) : (
          <HiLink size="1.5rem" />
        )}
      </CopyLink>
    </CopyToClipboard>
  );
};

export default CopyAnchor;
