import React from "react";
import styled from "styled-components";
import ScrollToTop from "../Design/ScrollToTop";
import WhitepaperContentContainer from "./WhitepaperContentContainer";
import WhitepaperNav from "./WhitepaperNav";
import { Helmet } from "react-helmet-async";

const Section = styled.section`
  position: relative;
  li {
    line-height: 1.5rem;
    margin: 5px 0;
  }
  h2,
  h3,
  h4,
  h5 {
    font-family: "Archivo", sans-serif;
  }
  h1 {
    font-size: 2.5rem;
    @media (min-width: 992px) {
      font-size: 3rem;
    }
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 5px;
    @media (min-width: 992px) {
      font-size: 2rem;
    }
  }

  h3 {
    color: var(--color-highlight);
  }
  p {
    font-size: 1.1rem;
    margin: 1rem 0;
  }
`;

const Whitepaper = () => {
  return (
    <Section>
      <Helmet>
        <title>Whitepaper | Pocket Godz</title>
      </Helmet>
      <ScrollToTop />
      <WhitepaperContentContainer />
      <WhitepaperNav />
    </Section>
  );
};

export default Whitepaper;
