import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ProgressBar from "react-scroll-progress-bar";
import pitViper from "../../../assets/images/traits/pit-viper.png";
import swords from "../../../assets/images/traits/swords.png";
import shield from "../../../assets/images/traits/shield.png";
import aviator from "../../../assets/images/traits/aviator.png";
import ninjaHeadband from "../../../assets/images/traits/ninja-headband.png";
import headband from "../../../assets/images/traits/headband.png";
import fighterMask from "../../../assets/images/traits/fighter-mask.png";
import goldGlasses from "../../../assets/images/traits/gold-glasses.png";
import teardrop from "../../../assets/images/traits/teardrop.png";
import whitepaperPDF from "../../../assets/pdf/pgz-whitepaper-v1.pdf";
import Fade from "react-reveal/Fade";
import LightSpeed from "react-reveal/LightSpeed";
import RubberBand from "react-reveal/RubberBand";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import godzToken from "../../../assets/images/icons/godz-token.png";

import background from "../../../assets/images/layered/background.png";
import body from "../../../assets/images/layered/body.png";

import {
  HiBookmark,
  HiBookOpen,
  HiCalendar,
  HiChip,
  HiClock,
  HiCode,
  HiCog,
  HiColorSwatch,
  HiDatabase,
  HiDesktopComputer,
  HiEmojiSad,
  HiFingerPrint,
  HiFire,
  HiHand,
  HiHeart,
  HiInformationCircle,
  HiLightningBolt,
  HiPuzzle,
  HiQuestionMarkCircle,
  HiShieldCheck,
  HiStar,
  HiUserGroup,
} from "react-icons/hi";
import LinkButton from "../Buttons/LinkButton";
import CopyAnchor from "../CopyAnchor";
import Footer from "../Footer";

const MainContainer = styled.section`
  position: relative;
  z-index: 1;
  width: 100vw;
  padding: 0 0 15vh 0;
  @media (min-width: 500px) {
    width: 75%;
    z-index: 2;
  }
`;
const Section = styled.section`
  a {
    text-decoration: underline;
  }
  word-break: break-word;
  white-space: normal;
  .aspect-square {
    aspect-ratio: 1/1;
  }
  max-width: 600px;
  margin: 30vh 0 0 2rem;
  padding: 0 10vw 0 5vw;
  @media (min-width: 992px) {
    margin: 50vh auto;
    max-width: 600px;
    padding: 0;
  }
  &.fullwidth {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
  }
`;
const Heading = styled.h1``;
const Subtitle = styled.p``;

const InlineVideo = styled.div`
  width: 100vw;
  margin-left: -13vw;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 100vw;
  @media (min-width: 992px) {
    height: 100vh;
    margin-left: -20vw;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const InlineCentered = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: -2.5rem;
  *:first-child {
    margin: 0;
    min-width: 2rem;
  }
  h2,
  h3,
  h4,
  h5 {
    margin: 0 0 0 0.5rem;
  }
`;

const Trait = styled.img`
  max-width: 200px;
  max-height: 150px;
  margin: 2rem 0;
`;

const Emoji = styled.span`
  font-family: Arial;
`;

const MobileOnly = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;

const Underline = styled.span`
  color: var(--color-highlight);
`;

const WhitepaperContentContainer = () => {
  const NoteRef = useRef(null);
  const IntroductionRef = useRef(null);
  const WhatRef = useRef(null);
  const OverviewRef = useRef(null);
  const P2ERef = useRef(null);
  const EthosRef = useRef(null);
  const TechnicalRef = useRef(null);
  const GameplayRef = useRef(null);
  const ModesRef = useRef(null);
  const GodzRef = useRef(null);
  const TimelineRef = useRef(null);
  const PDFRef = useRef(null);
  const [highlighted, setHighlighted] = useState(null);
  const [usedAnchor, setUsedAnchor] = useState(false);

  useEffect(() => {
    if (!usedAnchor && window.location.hash) {
      var element = document.getElementById(window.location.hash.split("#")[1]);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
        setUsedAnchor(true);
      }
    }
    let observer;
    let Refs = [
      NoteRef,
      IntroductionRef,
      WhatRef,
      OverviewRef,
      P2ERef,
      EthosRef,
      TechnicalRef,
      GameplayRef,
      ModesRef,
      GodzRef,
      TimelineRef,
      PDFRef,
    ];
    if (
      Refs.every((r) => {
        return Boolean(r.current);
      })
    ) {
      const options = {
        threshold: 0,
      };

      observer = new IntersectionObserver((entries, observer) => {
        var highest = null;
        entries.forEach((entry) => {
          const navElement = document.querySelector(
            `a[href="#${entry.target.id}"]`
          );
          if (entry.isIntersecting) {
            var height =
              window.pageYOffset + entry.target.getBoundingClientRect().top;
            if (highest) {
              if (highest.height < height) {
                highest = { navElement: navElement, height: height };
              }
            } else {
              highest = { navElement: navElement, height: height };
            }
          }
        });
        if (highest) {
          if (!highest.navElement.classList.contains("active")) {
            if (highlighted && highlighted !== highest.navElement) {
              highlighted.classList.remove("active");
            }
            setHighlighted(highest.navElement);
            highest.navElement.classList.add("active");
          }
        }
      }, options);
      for (const x of Refs) {
        observer.observe(x.current);
      }
    }

    return () => observer.disconnect();
  }, [
    NoteRef,
    IntroductionRef,
    WhatRef,
    OverviewRef,
    P2ERef,
    EthosRef,
    TechnicalRef,
    GameplayRef,
    ModesRef,
    GodzRef,
    TimelineRef,
    PDFRef,
    highlighted,
    usedAnchor,
  ]);

  return (
    <ParallaxProvider>
      <MainContainer>
        <ProgressBar bgcolor="var(--color-success)" />
        <Section>
          <LightSpeed left>
            <Heading>POCKET GODZ FIGHTER GAME</Heading>
          </LightSpeed>
          <LightSpeed left delay={500}>
            <Subtitle>WHITEPAPER V1</Subtitle>
          </LightSpeed>
        </Section>
        <Section>
          <Fade>
            <InlineCentered>
              <HiHeart size="2rem" color="var(--color-error)" />
              <h2
                ref={NoteRef}
                id="note"
                style={{ color: "var(--color-success)" }}
              >
                A note from the team
              </h2>
              <CopyAnchor anchor="note" />
            </InlineCentered>
            <p>
              Godz. We're so excited to share this journey with you all, and the
              best part is &mdash; it's only just beginning. HIYAH
              <Emoji>&#9876; &#x1F977;</Emoji>
            </p>
            <p>PS WEN LAMBO</p>
          </Fade>
        </Section>
        <Section>
          <Trait src={pitViper} />
          <InlineCentered>
            <HiBookmark size="2rem" />
            <h2 ref={IntroductionRef} id="introduction">
              Introduction
            </h2>
            <CopyAnchor anchor="introduction" />
          </InlineCentered>
          <Fade>
            <p>
              This whitepaper will give an overview of the Pocket Godz Fighter
              Game, in-game economy &amp; staking program.
            </p>
            <p>
              This is v1 of the whitepaper and will be updated as needed
              throughout the development cycle and as more features are added to
              the base game.
            </p>
            <MobileOnly>
              <p>
                If you prefer,{" "}
                <a href={whitepaperPDF}>
                  here's a PDF version of our whitepaper.
                </a>
              </p>
            </MobileOnly>
          </Fade>
        </Section>
        <Section>
          <Fade>
            <Trait src={swords} />
            <InlineCentered>
              <HiQuestionMarkCircle size="2rem" />
              <h2 ref={WhatRef} id="what">
                What are the Pocket Godz?
              </h2>
              <CopyAnchor anchor="what" />
            </InlineCentered>
            <p>
              The Pocket Godz (PGz) are a collection of 3000 randomly generated
              fighter NFTs on the Solana Blockchain.
            </p>
            <p>
              Each Godz is unique and comes with different traits and attributes
              varying in rarity.
            </p>
            <p>
              Godz holders will be able to use their unique NFT within the PGz
              fighter game and will benefit from the Pocket Godz play-to-earn
              mechanism.
            </p>
          </Fade>
          <ParallaxBanner
            layers={[
              { image: background, speed: -20 },
              { image: body, speed: 0 },
            ]}
            className="aspect-square"
          />
        </Section>
        <Section>
          <Fade>
            <Trait src={headband} />
            <InlineCentered>
              <HiBookOpen size="2rem" />
              <h2 ref={OverviewRef} id="overview">
                Pocket Godz Fighter Game Overview
              </h2>
              <CopyAnchor anchor="overview" />
            </InlineCentered>

            <p>
              The Pocket Godz Fighter Game is a classic 1v1 fighter game with
              play-to-earn (P2E) mechanics.
            </p>
            <p>
              Players will be able to fight against the computer, against other
              players and will be able to participate in bracket-style
              tournaments.
            </p>
            <p>
              The in-game economy will be facilitated through the use of the
              Godz ($GODZ) Token, which can be earned by staking Pocket Godz
              NFTs or purchasing $GODZ on the open market.
            </p>
            <InlineCentered>
              <HiStar size="2rem" />
              <h3>What is Play-to-Earn?</h3>
            </InlineCentered>

            <p>
              Play-to-Earn (P2E) games leverage NFT proof of ownership and
              decentralized finance technology to enable players to earn in-game
              resources, tokens or other rewards by simply playing a game. This
              proof of ownership allows players to own and subsequently trade
              in-game assets.
            </p>
            <p>
              Additionally, the use of non-fungible tokens empowers players to
              create in-game identities crafted around their unique characters
              &amp; avatars - a natural evolution in the microtransaction and
              game-skins meta that has been heavily popularized by games such as
              Counter Strike and Fortnite.
            </p>
            <p>
              There is no 'one size fits all' formula for P2E games, but at its
              most basic level, players can own and generate real-world value
              through the tokenization of in-game resources.
            </p>
          </Fade>
        </Section>
        <Section className="hl">
          <Fade>
            <Trait src={shield} />
            <InlineCentered>
              <HiEmojiSad size="2rem" />
              <h2 ref={P2ERef} id="p2e">
                P2E Pain Points
              </h2>
              <CopyAnchor anchor="p2e" />
            </InlineCentered>
            <p>
              It's estimated there are more than 3.2 billion gamers worldwide
              and the number of P2E gamers is less than 5 million. There is so
              much room for the industry to expand, yet growing pains are to be
              expected as the world of P2E gaming matures. The Pocket Godz team
              has identified two major pain points in P2E gaming:
            </p>
            <InlineCentered>
              <HiHand size="2rem" color="#fffff" />
              <h3>Accessibility</h3>
            </InlineCentered>
            <p>The first of these pain points is accessibility.</p>
            <p>
              Some of the most popular games today are free-to-play, allowing
              anyone with a computer and internet connection to play.
            </p>
            <p>
              The free-to-play model is generally monetized through
              microtransaction for the purchase of in-game skins or additional
              downloadable content.
            </p>
            <p>
              Conversely, access to P2E games is often gated in which only
              holders of a specific NFT are able to play the game and
              subsequently earn.
            </p>
            <p>
              There is some logic to this model as it concentrates rewards in
              the hands of early supporters and engaged players, but it also
              greatly restricts the potential player base to the amount of NFTs
              in the collection (typically 1000 - 10000 pieces).
            </p>
            <p>
              Additionally, popular NFTs that offer the ability to generate
              income beyond the value of the NFT often cost thousands of
              dollars, further restricting the potential player base.
            </p>
            <InlineCentered>
              <HiClock size="2rem" color="#fffff" />
              <h3>The Grind</h3>
            </InlineCentered>
            <p>
              The second pain point in P2E gaming is the grind or requiring
              players to dedicate a significant amount of time and energy to the
              game in order to generate meaningful value.
            </p>
            <p>
              Grinding to level up and receive unique rewards is a
              tried-and-true feature of many games, but time consuming grinds
              can lead to burnout and eventual abandonment of the game.
            </p>
            <p>
              Mass abandonment can be particularly dangerous to P2E games as
              earned value is derived from player interest and continued
              interaction with the game economy.
            </p>
            <p>
              As such, P2E games must balance value generated and time in a
              sustainable manner.
            </p>
          </Fade>
        </Section>
        <Section>
          <Fade>
            <Trait src={aviator} />
            <InlineCentered>
              <HiFingerPrint size="2rem" color="#fffff" />
              <h2 ref={EthosRef} id="ethos">
                Pocket Godz Ethos
              </h2>
              <CopyAnchor anchor="ethos" />
            </InlineCentered>
            <p>
              Play-to-Earn is an exciting frontier that ties the massive gaming
              industry to blockchain technology and gives power and ownership to
              the players, but it is not without its flaws.
            </p>
            <p>
              The Pocket Godz Fighter Game strives to fix the aforementioned
              pain points and build a sustainable, fun and long term P2E model:
            </p>
            <InlineCentered>
              <HiHand size="2rem" color="#fffff" />
              <h3>Accessibility</h3>
            </InlineCentered>
            <p>
              Anyone with a Solana wallet will be able to connect and play the
              Pocket Godz Fighter Game.
            </p>
            <p>
              Non-holders will still be able to wager and earn Godz Token,
              albeit in a limited manner. This non-gated approach expands the
              potential market of players from 3000 to millions, which directly
              increases the amount of rewards and value for PGz holders.{" "}
            </p>

            <InlineCentered>
              <HiClock size="2rem" color="#fffff" />
              <h3>The Grind</h3>
            </InlineCentered>
            <p>
              Replace the usual grind of P2E games with adrenaline pumping
              fast-paced fun that still provides substantial opportunities for
              player-generated value.
            </p>
            <p>
              Individual fight rounds will take no longer than a few minutes and
              offer the ability to wager and earn Godz Tokens in that short time
              frame.
            </p>
            <p>
              Additionally, there will be a large amount of move combos for
              players to learn, which incentives skill over a time grind.
            </p>
          </Fade>
        </Section>
        <Section>
          <Fade>
            <Trait src={fighterMask} />
            <InlineCentered>
              <HiCode size="2rem" />
              <h2 ref={TechnicalRef} id="technical">
                Technical info
              </h2>
              <CopyAnchor anchor="technical" />
            </InlineCentered>

            <p>
              The Pocket Godz Fighter Game is being developed in conjunction
              with{" "}
              <a
                href="https://alimango.studio/"
                target="_blank"
                rel="noreferrer"
              >
                Alimango Studios
              </a>
              , a talented web3 game developer team that includes game economy
              experts.
            </p>
            <p>
              The game will be a WebGL browser game with multiple smart
              contracts that enable decentralized wagering.
            </p>
            <p>
              Due to the skill-based nature of the game, a robust anti-cheat
              system will be implemented in order to protect both players and
              the in-game economy from exploitation.
            </p>
            <p>
              PGz holders will be able to use their uniquely generated NFTs in
              the game.
            </p>
            <p>
              Game stats such as wins &amp; losses, wagering history and
              tournament results will be tied to both players (based on wallet
              address) as well as the individual NFTs.
            </p>
            <p>
              The NFT-based statistics will carry over even after sales on NFT
              marketplaces, thus creating a unique layer to the classic rarity
              system.
            </p>
            <p>
              Those who do not hold any Pocket Godz NFTs will still be able to
              play the game with a selection of base characters, but will be
              restricted in their wager amounts, participation in community
              tournaments and, of course, will not be privy to passive rewards
              from the game.
            </p>
          </Fade>
        </Section>
        <Section>
          <Fade>
            <Trait src={goldGlasses} />
            <InlineCentered>
              <HiPuzzle size="2rem" />
              <h2 ref={GameplayRef} id="gameplay">
                Gameplay
              </h2>
              <CopyAnchor anchor="gameplay" />
            </InlineCentered>
            <p>
              The Pocket Godz Fighter Game will play similar to classic fighter
              games that you know and love.
            </p>
            <p>Matches will initially be 1v1 on flat stages.</p>
            <p>
              The picture below shows a preview of one of the fight environments
              (Forest) as well as the general feel of the game:
            </p>
            <p>*Sample Preview. Final Design is subject to change.</p>
            <InlineVideo>
              <iframe
                src="https://www.youtube.com/embed/videoseries?list=PLSZvAnbqltufKYI05YSbNjjygFYpf2phJ&mute=1&autoplay=1&controls=0&loop=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </InlineVideo>
            <p>
              Team fights and more unique, layered stages are long term upgrades
              planned for after the release of the core game.
            </p>
            <p>
              Movement will be controlled by the W-A-S-D keys and there will be
              a multitude of fight moves as well as powerful combos for players
              to master, designed to be challenging but engaging.
            </p>

            <InlineCentered>
              <HiLightningBolt size="2rem" />
              <RubberBand>
                <h3>Power Ups</h3>
              </RubberBand>
            </InlineCentered>
            <p>
              In addition, you will be able to purchase Power ups and regular /
              returning players will also be rewarded with a Daily Power Up
              Bonus / Comeback Bonus in order to reward players who play on a
              regular basis.
            </p>
            <p>
              The Power Ups will include small effects that can be used in-game,
              such as a "Rage Power" that gives you higher attack damage for a
              short period of time or a Resistance Power that allows you to tank
              more damage from the other player.
            </p>
            <p>
              Power Ups can also include a multiplier for winnings or
              "Insurance" for a game, which allows you to keep your bet if you
              lose.
            </p>
          </Fade>
        </Section>
        <Section>
          <Fade>
            <Trait src={teardrop} />
            <InlineCentered>
              <HiColorSwatch size="2rem" />
              <h2 ref={ModesRef} id="modes">
                Game modes
              </h2>
              <CopyAnchor anchor="modes" />
            </InlineCentered>
            <br />
            <br />
            <InlineCentered>
              <HiDesktopComputer size="2rem" />
              <h3>Player v. Computer</h3>
            </InlineCentered>
            <p>
              In the player v. computer (PvC) mode players face off against
              computer-controlled Godz at various difficulty levels. The initial
              goal of the PvC mode will be to provide players the opportunity to
              practice the game at different difficulty levels and prepare for
              the PvP mode where wagers are enabled.
            </p>
            <p>
              Although the PvC mode is not the main facet of the P2E economy, it
              will provide holders the opportunity to 'grind' results and beat
              the computer. The leaderboard technology will be leveraged to
              distribute weekly airdrops to the top of the leaderboard.
            </p>
            <ul>
              <li>
                <strong>Benefits for all Players</strong>: Climb the leaderboard
                and earn weekly rewards for beating the computer.
              </li>
              <li>
                <strong>Benefit for Holders</strong>: Weekly leaderboard
                exclusively for PGz holders. Additional airdrop incentive for
                holders to play a certain amount of times per week.
              </li>
            </ul>
            <InlineCentered>
              <HiUserGroup size="2rem" />
              <h3>Player v. Player</h3>
            </InlineCentered>
            <p>
              The core feature of the Pocket Godz Fighter Game is the PvP arena.
            </p>
            <p> In the PvP mode, players face off against other players. </p>
            <p>
              Each player deposits a pre-agreed amount of $GODZ tokens to start
              the match.
            </p>
            <p>The winner of the match takes 96.5% of the total wager pool.</p>
            <p>
              The remaining 3.5% will be split between the PGz team, the PGz
              community (distributed via the staking protocol) and the burn
              wallet.
            </p>
            <p>
              In addition to the wager mode, a 'just-for-fun' PvP mode will be
              made available for Godz to hone their skills against other actual
              players.
            </p>
            <p>
              Furthermore, a major planned upgrade for the PvP mode is the
              ability to invite specific players to a party, enabling battles
              against friends.
            </p>
            <p>
              The PvP mode will be made available after the PvC mode testing
              window.
            </p>
            <ul>
              <li>
                <strong>Benefits for all Players</strong>: Wager against other
                players and earn $GODZ.
              </li>
              <li>
                <strong>Benefits for Holders</strong>: Earn distributions from
                the wager fee. Higher wager limits.
              </li>
            </ul>
            <InlineCentered>
              <HiShieldCheck size="2rem" />
              <h3>Bracket Style Tournaments</h3>
            </InlineCentered>
            <p>
              The final main mode for the Pocket Godz Fighter Game are bracket
              style tournaments.
            </p>
            <p>
              These tournaments follow standard bracket style format in which
              the winner moves on and the loser is eliminated.
            </p>
            <p>
              The prize pool for each tournament will be set and distributed
              automatically to the winner at the conclusion of the tournament.
            </p>
            <p>
              Prizes include but are not limited to $GODZ tokens, SOL, special
              honoraries created by our partner artists, limited edition Pocket
              Godz NFTs and 1/1 art.
              <br />
              <br />
              <HiInformationCircle size="1rem" />
              {"  "}
              <Underline>
                The majority of tournaments will be exclusively for Pocket Godz
                holders.
              </Underline>
            </p>
            <p>Tournaments will also allow for spectator viewing.</p>
            <p>
              Finally, the Pocket Godz team hopes to use the tournament format
              to facilitate partnerships with other NFT communities.
            </p>
            <p>
              After testing for the mode, other projects, DAOs, communities or
              even individuals will be able to spend $GODZ tokens to run
              whitelisted tournaments and set unique prize pools.
            </p>
            <ul>
              <li>
                <strong>Benefits for all Players</strong>: Spectator viewing and
                participation in public tournaments.
              </li>
              <li>
                <strong>Benefits for Holders</strong>: Participate in all
                tournaments, win exclusive prizes.
              </li>
            </ul>
          </Fade>
        </Section>
        <Section>
          <Fade>
            <Trait src={godzToken} />
            <InlineCentered>
              <HiChip size="2rem" />
              <h2 ref={GodzRef} id="godztoken">
                $GODZ Token
              </h2>
              <CopyAnchor anchor="godztoken" />
            </InlineCentered>

            <p>
              The $GODZ token is the currency of the Pocket Godz Fighter Game.
            </p>
            <p>
              The entire P2E economy will be centered around this token; $GODZ
              will facilitate the staking system, the wagering system and will
              be used for upcoming drops &amp; upgrades to the PGz ecosystem.
            </p>
            <p>
              A portion of secondary sale royalties will be funneled directly
              into the token's LP in order to ensure its long term
              sustainability.
            </p>

            <InlineCentered>
              <HiCog size="2rem" />
              <h3>Utility of the Godz Token</h3>
            </InlineCentered>
            <p>
              $GODZ will be the only token accepted in the Pocket Godz Fighter
              Game unless a partner project briefly whitelists their token for a
              community tournament.
            </p>
            <p>
              All wagers and payouts will be done in $GODZ - to experience all
              features of the game and play-to-earn, players need to own $GODZ
              tokens.
            </p>
            <p>
              By centering the game and its fast-paced, addicting gameplay
              around the $GODZ token, demand and consequent buying pressure will
              be self-sustaining.
            </p>
            <p>
              Beyond the facilitation of core gameplay functionality, $GODZ will
              be used to purchase:
            </p>
            <ul>
              <li>In-game upgrades &amp; power-ups</li>
              <li>Access to tournament hosting</li>
              <li>Custom honoraries</li>
              <li>1/1 art from partner artists</li>
              <li>Official Pocket Godz Merch</li>
            </ul>
            <p>
              The list of utilities for the $GODZ token is subject to change and
              grow as we expand the game ecosystem and our partnership network
              of artists and projects.
            </p>

            <InlineCentered>
              <HiDatabase size="2rem" />
              <h3>Staking Mechanics</h3>
            </InlineCentered>
            <p>
              Pocket Godz NFT holders will be able to stake their NFTs to earn
              $GODZ tokens. The staking system will go live (along with a
              special incentivized lock-up period) in early March before the PvC
              mode goes live.
            </p>
            <p>
              One of the main challenges that we will face in the development of
              the staking protocol is that holders will need their NFT in-wallet
              to use it in the game.
            </p>
            <p>
              There are a few possible solutions including an in-wallet staking
              system in which rewards are randomly airdropped after different
              epochs or developing a method for the game to read staked NFT and
              wallet data.
            </p>
            <p>
              The community's feedback will play a large part in this decision.
            </p>
            <p>
              The full tokenomics as well as staking distribution details will
              be announced in whitepaper v2 in early March. Shortly after this
              release, the staking protocol will go live for all Pocket Godz NFT
              holders.
            </p>
          </Fade>
        </Section>
        <Section>
          <Fade>
            <Trait src={ninjaHeadband} />
            <InlineCentered>
              <HiCalendar size="2rem" />
              <h2 ref={TimelineRef} id="timeline">
                Development Timeline
              </h2>
              <CopyAnchor anchor="timeline" />
            </InlineCentered>
            <p>
              Development on the Pocket Godz Fighter Game is well underway, but
              the game will not be released until after mint.
            </p>
            <p>
              Although this approach is not ideal for some, the Pocket Godz team
              is excited to collaborate with our community throughout the
              development process, providing the ability to make a direct impact
              on the evolution of the game and ensure that it is a desirable
              product for all stakeholders.
            </p>
            <InlineCentered>
              <HiClock size="2rem" /> <h3>Timeline</h3>
            </InlineCentered>
            <ul>
              <li>
                Staking Protocol &amp; Release of $GODZ Token
                <ul>
                  <li>Early-mid March 2022</li>
                </ul>
              </li>
              <li>
                Player v. Computer
                <ul>
                  <li>Alpha Test mid-late March</li>
                  <li>Beta Release mid May</li>
                </ul>
              </li>
              <li>
                Player v. Player
                <ul>
                  <li>Alpha Test with spectator viewing mid-late April</li>
                  <li>Beta Release mid May</li>
                </ul>
              </li>
              <li>
                Bracket Tournaments
                <ul>
                  <li>Late May Alpha Test</li>
                  <li>Beta Release Mid June</li>
                </ul>
              </li>
              <li>
                Full Game Release
                <ul>
                  <li>July 2022</li>
                </ul>
              </li>
            </ul>
            <p>
              By staggering the release of different features of the game, we
              are able to continuously deliver on our roadmap without keeping
              the community waiting for months on end.
            </p>
            <p>
              Additionally, the alpha rounds will allow us to receive as much
              feedback from the players as possible in order to ensure that the
              game is engaging, bug-free, profitable for our holders and, most
              importantly, fun.
            </p>
            <p>
              There is no secret that the attention span in the crypto and NFT
              space is quite short and that trust issues persist, especially in
              the Solana ecosystem as of late.
            </p>
            <p>
              To demonstrate our commitment to the long term development of the
              Pocket Godz Fighter Game, the team will be locking a portion of
              raised funds in a publicly viewable vault, staggered to be
              released as certain game development milestones are reached.
            </p>
            <p>
              Furthermore, the staking protocol will have a locking system that
              incentives holders to lock their NFTs until the alpha versions of
              the game are released.
            </p>
            <p>
              The Pocket Godz team is excited to be taking this massive journey
              together with our community and is looking forward to developing
              and subsequently releasing the best fighter game possible.
            </p>
          </Fade>
        </Section>
        <Section>
          <InlineCentered>
            <HiFire size="2rem" />
            <h2 ref={PDFRef} id="pdf">
              Phew. That's a lot. WAGMI frens.
            </h2>
            <CopyAnchor anchor="pdf" />
          </InlineCentered>
          <br />
          <br />
          <Fade>
            <LinkButton href={whitepaperPDF}>
              Download Whitepaper PDF
            </LinkButton>
            <br />
            <LinkButton href="https://discord.gg/bN2KzaTNeS">
              Join Discord
            </LinkButton>
            <br />
            <LinkButton href="/" internal>
              Back to main site
            </LinkButton>
          </Fade>
        </Section>
      </MainContainer>
      <Footer />
    </ParallaxProvider>
  );
};

export default WhitepaperContentContainer;
