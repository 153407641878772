import React from "react";
import AppShell from "./AppShell";
import Divider from "./components/Design/Divider/Divider";
import TextImageSection from "./components/TextImageSection/TextImageSection";
import pocketGodzLogo from "../assets/images/logos/pocket-godz-transparent.png";
import GodzGif from "../assets/images/godz/animated/assorted-square.gif";
import { Helmet } from "react-helmet-async";

const AboutPage = () => {
  return (
    <AppShell>
      <Helmet>
        <title>About | Pocket Godz</title>
      </Helmet>
      <TextImageSection
        image={GodzGif}
        id="About"
        title="Pick your fighter."
        alone
      >
        <p>
          Militaries &amp; warriors historically have a vicious relationship
          with art. From cultural looting conducted by Roman Gladiators to the
          destruction of religious works during the Protestant Reformation and
          the infamous burning of Gustav Klimt's masterpieces by the SS in World
          War II, works of art often fall victim to the brutality of war.
        </p>
        <p>
          Pocket Godz are here to flip the script by protecting and supporting
          art. These mighty warriors must train daily in the Pocket Godz Fighter
          game dojo to fight off anyone who may wish harm to art, artists and
          culture. The PGz have decided to use their collective power to enter
          the Solana Metaverse due to the incredible art and community on this
          chain.
        </p>
        <p>
          Together, the Pocket Godz and the Solana community will propel 1/1
          artists to new heights. HIYAH
        </p>
      </TextImageSection>
      <Divider />
      <TextImageSection
        image={pocketGodzLogo}
        title="Supporting 1/1 Art on Solana"
        reversed
      >
        <p>
          Pocket Godz and the PGz team are committed to supporting 1/1 art and
          artists on the Solana blockchain. PGz will be hosting bi-weekly
          twitter spaces to give a platform to up-and-coming artists.
        </p>
        <p>
          We plan to partner with various artists to create honorary Godz to
          offer as prizes for tournaments.
        </p>
        <p>
          We hope the Pocket Godz community rallies around 1/1 artists and will
          also vote to store and support Solana art in the PGz community vault.
        </p>
      </TextImageSection>
    </AppShell>
  );
};

export default AboutPage;
