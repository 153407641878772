import React from "react";
import styled from "styled-components";

const ButtonStyling = styled.a`
  font-family: "Tight Pixel", Arial, Helvetica, sans-serif;
  text-decoration: none !important;
  cursor: pointer;
  color: var(--color-background);
  display: block;
  padding: 1rem 1.5rem;
  background-color: var(--color-text-light);
  font-size: 1.5rem;
  text-align: center;
  &:hover {
    background-color: var(--color-hover);
  }
  &.sm {
    font-size: 1rem;
    @media (max-width: 480px) {
      font-size: 1rem;
      padding: 5px;
    }
  }
  &.inverted {
    border: 1px solid var(--color-text-light);
    color: var(--color-text-light);
    background-color: var(--text-color-dark);
    &:hover {
      color: var(--color-text-dark);
      background-color: var(--color-text-light);
    }
  }
  &.highlighted {
    font-size: 1.5rem;
    border: 1px solid var(--color-success);
    background-color: var(--color-success);
    &:hover {
      color: var(--color-success);
      background-color: rgba(0, 0, 0, 0);
    }
  }
  &.larger {
    border: 5px solid var(--color-success);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-decoration: none;
    font-size: 1.75rem;
  }
`;

const LinkButton = (props: any) => {
  var linkAttrs: any = { className: [] };
  if (!props.internal) {
    linkAttrs = {
      ...linkAttrs,
      ...{
        rel: "noreferrer",
        target: "_blank",
      },
    };
  }
  if (props.inverted) {
    linkAttrs.className.push("inverted");
  }
  if (props.small) {
    linkAttrs.className.push("sm");
  }
  if (props.highlighted) {
    linkAttrs.className.push("highlighted");
  }
  if (props.larger) {
    linkAttrs.className.push("larger");
  }
  return (
    <ButtonStyling href={props.href} {...linkAttrs}>
      {props.children}
    </ButtonStyling>
  );
};

export default LinkButton;
