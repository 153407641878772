import React from "react";
import styled from "styled-components";
import ScrollToTop from "./components/Design/ScrollToTop";
import Footer from "./components/Footer";
import Header from "./components/Header";

const MainContainer = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 0 24px;
  margin: auto;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;

  @media (min-width: 992px) {
    padding: 0 36px;
  }
`;

const ContentStyling = styled.div`
  a {
    text-decoration: underline;
  }
`;

const AppShell = (props: any) => {
  return (
    <MainContainer>
      <ScrollToTop />
      <Header ignoreActionButton={props.ignoreActionButton} />
      <ContentStyling>{props.children}</ContentStyling>
      <Footer />
    </MainContainer>
  );
};

export default AppShell;
