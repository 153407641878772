import React from "react";
import styled from "styled-components";

const ButtonStyling = styled.button`
  font-family: "Tight Pixel", Arial, Helvetica, sans-serif;
  cursor: pointer;
  color: var(--color-background);
  display: block;
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: var(--color-text-light);
  font-size: 1.5rem;
  text-align: center;
  &:hover {
    background-color: var(--color-hover);
  }
  &.sm {
    font-size: 1rem;
    @media (max-width: 480px) {
      font-size: 1rem;
      padding: 5px;
    }
  }
  &.inverted {
    border: 1px solid var(--color-text-light);
    color: var(--color-text-light);
    background-color: var(--text-color-dark);
    &:hover {
      color: var(--color-text-dark);
      background-color: var(--color-text-light);
    }
  }
`;

const ActionButton = (props: any) => {
  var linkAttrs: any = { className: [] };
  if (props.inverted) {
    linkAttrs.className.push("inverted");
  }
  if (props.small) {
    linkAttrs.className.push("sm");
  }
  return <ButtonStyling {...linkAttrs}>{props.children}</ButtonStyling>;
};

export default ActionButton;
