import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logos/pocket-godz-transparent.png";
import LinkButton from "./Buttons/LinkButton";

const Navbar = styled.header`
  font-family: "Tight Pixel", Arial, Helvetica, sans-serif;
  width: 100%;
  display: flex;
  padding: 24px 0;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  position: relative;
  > a {
    z-index: 2;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    img {
      max-height: 30px;
    }
    @media (min-width: 992px) {
      font-size: 1.5rem;
    }
  }

  @media (min-width: 992px) {
    padding: 36px 0;

    img {
      display: inline-block;
      max-width: 70px;
      height: auto;
    }
  }
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 38px;
  flex-grow: 1;
  span.pipe {
    display: none;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    text-align: center;
    background: linear-gradient(45deg, #202846, #292f45);
    margin: 0;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
    transition: 0.3s ease-in;
    padding-left: 0;
    z-index: 1;
    &.show-nav {
      transform: translateX(0%);
    }
    @media (min-width: 480px) {
      span.pipe {
        display: block;
      }
    }

    a {
      margin-bottom: 16px;
      &:hover {
        color: var(--color-hover);
      }
      &.selected {
        color: var(--color-hover);
      }
    }
  }

  span.pipe {
    font-size: 1.5rem;
  }

  .toggle-btn {
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 1000;
    padding: 5px;
    margin-right: 16px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    span {
      display: block;
      width: 30px;
      height: 2px;
      background-color: #fff;
      transition: 0.2s ease-in;

      &:nth-child(1) {
        transform: translateY(-5px);
      }

      &:nth-child(3) {
        transform: translateY(5px);
      }
    }

    &.toggle-btn-active {
      span {
        &:nth-child(1) {
          transform: translateY(2px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translateX(-100%);
        }

        &:nth-child(3) {
          transform: translateY(-2px) rotate(-45deg);
        }
      }
    }
  }

  .connect-container {
    display: flex;
  }

  @media (min-width: 992px) {
    .nav-links {
      flex-grow: 1;
      background: transparent;
      flex-direction: row;
      position: relative;
      transform: translateX(0);
      transition: none;
      display: flex;
      justify-content: flex-start;

      a {
        font-size: 1.25rem;
        margin-left: 24px;
        margin-bottom: 0;
      }

      a:first-child {
        margin-left: 0;
      }
    }

    .toggle-btn {
      display: none;
    }

    .connect-container {
      margin-left: auto;
      margin-right: 32px;
    }
  }
  @media (max-width: 680px) {
    order: 3;
  }
`;

const MenuItem = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  font-size: 2.5rem;
  a {
    color: #fff;
  }
`;

const Action = styled.div`
  @media (max-width: 680px) {
    order: 2;
    flex-basis: 50%;
  }
`;

const Header = (props) => {
  const [isOpen, setNav] = useState(false);
  const location = useLocation();

  const toggleNav = () => {
    setNav((isOpen) => !isOpen);
  };

  const isActive = (path) => {
    return path === location.pathname;
  };

  return (
    <Navbar id="home">
      <a href="/">
        <img src={logo} alt="Pocket Godz Logo" />
        <span>Pocket Godz</span>
      </a>
      <MenuContainer>
        <div className={isOpen ? "nav-links show-nav" : "nav-links"}>
          <MenuItem href="/" className={isActive("/") ? "selected" : null}>
            Home
          </MenuItem>
          <MenuItem
            href="/about"
            className={isActive("/about") ? "selected" : null}
          >
            About
          </MenuItem>
          <MenuItem
            href="/whitepaper"
            className={isActive("/whitepaper") ? "selected" : null}
          >
            Whitepaper
          </MenuItem>
          <MenuItem
            href="/the-game"
            className={isActive("/the-game") ? "selected" : null}
          >
            The Game
          </MenuItem>
          <MenuItem
            href="/roadmap"
            className={isActive("/roadmap") ? "selected" : null}
          >
            Roadmap
          </MenuItem>
          <MenuItem
            href="/wl-verify"
            className={isActive("/wl-verify") ? "selected" : null}
          >
            WL Verify
          </MenuItem>
          <MenuItem
            href="/faq"
            className={isActive("/faq") ? "selected" : null}
          >
            FAQ
          </MenuItem>
        </div>
        <button
          className={isOpen ? "toggle-btn toggle-btn-active" : "toggle-btn"}
          type="button"
          onClick={toggleNav}
          aria-label="Menu Button"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </MenuContainer>
      {!props.ignoreActionButton ? (
        <Action>
          <LinkButton
            highlighted
            small
            href="https://magiceden.io/marketplace/pocket_godz"
          >
            BUY ON ME
          </LinkButton>
        </Action>
      ) : null}
    </Navbar>
  );
};

export default Header;
