import React from "react";
import styled from "styled-components";

const Section = styled.section`
  width: 100%;
  position: relative;
  margin: 0 auto;
  h2 {
    max-width: 100%;
    font-size: 48px;
    margin-bottom: 30px;
    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }
  p {
    font-size: 1.1rem;
  }

  @media (min-width: 480px) {
    h2 {
      font-size: 64px;
    }
  }

  @media (min-width: 992px) {
    margin: 100px auto;
    h2 {
      font-size: 64px;
    }
  }
  &.no-margin {
    margin: 0 auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  p {
    margin-bottom: 24px;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    padding: 50px 0 0;
  }
`;

const LeftGrid = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  margin-top: 0;
  &.reversed {
    order: 2;
  }

  h1 {
    font-size: 64px;
    margin-bottom: 32px;
  }

  h2 {
    margin-bottom: 16px;
  }

  @media (min-width: 480px) {
    h1 {
      font-size: 80px;
    }
  }

  @media (min-width: 992px) {
    flex-basis: 50%;
    max-width: 50%;

    h1 {
      font-size: 105px;
      margin-bottom: 40px;
    }
  }
`;

const RightGrid = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 45%;
  }
  &.reversed {
    order: 1;
    img {
      max-width: 50%;
      width: auto;
      @media (min-width: 992px) {
        max-height: 60vh;
      }
    }
  }

  @media (min-width: 992px) {
    img {
      max-width: 50%;
    }
    display: flex;
    flex-basis: 47.5%;
    max-width: 47.5%;
  }
`;

const TextImageSection = (props) => {
  return (
    <Section id={props.id} className={props.alone ? "no-margin" : ""}>
      <h2>{props.title}</h2>
      <Container>
        <LeftGrid className={props.reversed ? "reversed" : ""}>
          {props.children}
        </LeftGrid>
        <RightGrid className={props.reversed ? "reversed" : ""}>
          <img src={props.image} alt={props.imageAltText} />
        </RightGrid>
      </Container>
    </Section>
  );
};

export default TextImageSection;
