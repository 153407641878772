import React from "react";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import lightCharacterWaiting from "../../assets/images/godz/animated/white-waiting.gif";
import lightCharacter from "../../assets/images/godz/animated/white-special.gif";
import darkCharacterWaiting from "../../assets/images/godz/animated/black-waiting.gif";
import darkCharacter from "../../assets/images/godz/animated/black-special.gif";

const Avatar = styled.section`
  padding-top: 100px;
  @keyframes jump1 {
    0% {
      transform: scale(1, 1) translateY(0px);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(0px);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-150px);
    }
    50% {
      transform: scale(1, 1) translateY(-10px);
    }
    51% {
      transform: scale(1, 1) translateY(0px);
    }
    100% {
      transform: scale(1, 1) translateY(0px);
    }
  }
  @keyframes jump2 {
    0% {
      transform: scale(1, 0.95) translateY(0px);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(0px);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-150px);
    }
    50% {
      transform: scale(1, 1) translateY(-10px);
    }
    51% {
      transform: scale(1, 1) translateY(0px);
    }
    100% {
      transform: scale(1, 1) translateY(0px);
    }
  }
  img {
    cursor: pointer;
    margin: 1rem auto;
    padding-right: 15px;
    &.jump1 {
      animation: jump1 1s linear 1 forwards;
    }
    &.jump2 {
      animation: jump2 1s linear 1 forwards;
    }
  }
  div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto 50px auto;
    button {
      color: white;
      cursor: pointer;
      margin: 0 5px 5px 0px;
      padding: 20px 5px;
      width: 40%;
      @media (min-width: 980px) {
        width: 20%;
        padding: 10px;
      }
    }
    @media (min-width: 980px) {
      width: 50%;
    }
  }
`;

const Jumper = () => {
  const [jumpClass, setClass] = useState("jump1");
  const [doingSpecial, doSpecial] = useState(false);
  const [isPlayerOne, setIsPlayerOne] = useState(true);
  let timer;

  const makeItJump = useCallback(() => {
    setClass(jumpClass === "jump1" ? "jump2" : "jump1");
  }, [jumpClass]);

  useEffect(() => {
    const keyUpHandler = (e) => {
      if (e.key === "w") {
        makeItJump();
      }
      if (e.key === "s") {
        doSpecial(true);
      }
      if (e.key === "a") {
        setIsPlayerOne(true);
      }
      if (e.key === "d") {
        setIsPlayerOne(false);
      }
    };
    document.addEventListener("keyup", keyUpHandler);
    if (doingSpecial) {
      clearTimeout(timer);
      setTimeout(() => {
        doSpecial(false);
      }, 4000);
    }
    return function cleanup() {
      document.removeEventListener("keyup", keyUpHandler);
    };
  }, [makeItJump, isPlayerOne, doingSpecial, doSpecial, timer]);

  const setPlayer = (state) => {
    setIsPlayerOne(state);
    if (doingSpecial) {
      doSpecial(false);
    }
    document.activeElement.blur();
  };

  const getCharacter = () => {
    if (isPlayerOne) {
      return doingSpecial ? lightCharacter : lightCharacterWaiting;
    } else {
      return doingSpecial ? darkCharacter : darkCharacterWaiting;
    }
  };

  return (
    <>
      <Avatar>
        <img
          className={jumpClass}
          onClick={() => makeItJump()}
          src={getCharacter()}
          height="150"
          alt="NFT character"
        />
        <div>
          <button onClick={() => makeItJump(true)}>Jump [w]</button>
          <button onClick={() => doSpecial(true)}>Special [s]</button>
          <button onClick={() => setPlayer(true)}>P1 [a]</button>
          <button onClick={() => setPlayer(false)}>P2 [d]</button>
        </div>
      </Avatar>
    </>
  );
};

export default Jumper;
