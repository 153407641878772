import React from "react";
import styled from "styled-components";
import Jumper from "./Jumper";

const Section = styled.section`
  text-align: center;
  @keyframes blinker {
    49% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
  }

  & > h2 {
    animation: blinker 1.5s linear infinite;
    font-size: 48px;
    margin-bottom: 30px;
    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }

  @media (min-width: 480px) {
    h2 {
      font-size: 34px;
    }
  }

  @media (min-width: 992px) {
    padding: 50px 0 50px 0;

    h2 {
      font-size: 64px;
    }
  }
`;

const ReadySection = () => {
  return (
    <Section id="ready">
      <h2>Ready player one?</h2>
      <Jumper />
    </Section>
  );
};

export default ReadySection;
