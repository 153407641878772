import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import WL from "../data/wl";
import pocketGod from "../assets/images/godz/animated/white-waiting.gif";
import VerifyPopup from "./components/VerifyPopup";
import AppShell from "./AppShell";
import ActionButton from "./components/Buttons/ActionButton";
import WLBar from "./components/WLBar";
var sha256 = require("js-sha256");

const Container = styled.section`
  img {
    max-height: 10vh;
    margin: 0 auto 1rem auto;
  }
  margin: 0 auto;
  max-width: 600px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  a {
    text-decoration: underline;
  }
  h1 {
    width: 100%;
    font-size: 1.5rem;
    @media (min-width: 992px) {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
  }
  > p {
    margin-bottom: 1rem;
    @media (min-width: 992px) {
      margin-bottom: 2rem;
    }
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  input {
    margin-bottom: 10px;
    width: 100%;
    @media (min-width: 992px) {
      padding-left: 10px;
      height: 3rem;
      width: 100%;
    }
  }
`;

const SmallText = styled.p`
  font-size: 0.8rem;
  font-family: Arial;
  text-align: left;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid var(--color-success);
  @media (min-width: 992px) {
    font-size: 0.9rem;
  }
`;

const LastUpdated = styled.p`
  margin-top: 0.5rem;
  font-size: 0.8rem;
`;

const Verify = () => {
  const [whitelisted, setWhitelisted] = useState<boolean | null>(null);
  const [input, setInput] = useState<string | null>(null);

  const CheckWL = (e: any) => {
    e.preventDefault();
    (document.activeElement as HTMLElement).blur();
    if (input) {
      let hashedValue = sha256(input);
      setWhitelisted(WL.data.indexOf(hashedValue) !== -1);
    } else {
      setWhitelisted(false);
    }
  };

  const userTyping = (e: any) => {
    setWhitelisted(null);
    setInput(e.target.value.trim());
  };

  return (
    <AppShell ignoreActionButton>
      <Container>
        <Helmet>
          <title>Whitelist verification | Pocket Godz</title>
        </Helmet>
        <img src={pocketGod} alt="Pocket Godz Character" />
        <h1>Whitelist verification</h1>
        <p>Use this form to check if a wallet has WL for PGz mint.</p>
        <FormWrapper>
          <form onSubmit={CheckWL}>
            <SmallText>
              &#128274; Wallet addresses are encrypted using one-way SHA256
              cryptography. Verification is completed solely within your
              browser.
            </SmallText>
            <input
              placeholder="Wallet address..."
              required
              onChange={userTyping}
              type="text"
              autoComplete="off"
              autoCapitalize="off"
              autoFocus={true}
              spellCheck="false"
              autoCorrect="off"
            />
            <ActionButton inverted>Verify wallet</ActionButton>
            {whitelisted !== null ? (
              <VerifyPopup whitelisted={whitelisted} />
            ) : null}
            <WLBar />
            <LastUpdated>Whitelist finalised.</LastUpdated>
            <br />
          </form>
        </FormWrapper>
      </Container>
    </AppShell>
  );
};

export default Verify;
