import React, { useState } from "react";
import styled from "styled-components";
import god from "../../../assets/images/godz/pocket-godz-tight.png";

const Section = styled.section`
  h2 {
    margin: 2rem auto 0 auto;
    font-size: 1.5rem;
    font-family: "Tight Pixel", Arial, Helvetica, sans-serif !important;
  }
  font-size: 0.9rem;
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 10%;
  @media (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 38px;
    flex-grow: 1;
  }
  span.pipe {
    display: none;
  }
  @media (min-width: 992px) {
    width: 25%;
  }

  span.pipe {
    font-size: 1.5rem;
  }
  button {
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 1000;
    padding: 5px;
    margin-right: 30px;
    margin-top: 30px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    @media (min-width: 992px) {
      display: none;
    }

    span {
      display: block;
      width: 30px;
      height: 2px;
      background-color: #fff;
      transition: 0.2s ease-in;

      &:nth-child(1) {
        transform: translateY(-5px);
      }

      &:nth-child(3) {
        transform: translateY(5px);
      }
    }

    &.toggle-btn-active {
      span {
        &:nth-child(1) {
          transform: translateY(2px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translateX(-100%);
        }

        &:nth-child(3) {
          transform: translateY(-2px) rotate(-45deg);
        }
      }
    }
  }
`;

const PocketGod = styled.img`
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;

  @media (min-width: 992px) {
    display: block;
    right: 2.5vw;
    height: 50vh;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0 0 0 0 1rem;
  padding: 0;
`;

const ListItem = styled.li`
  line-height: 1rem !important;
  margin: 0 0 0.75rem 0 !important;
  @media (min-width: 992px) {
    margin: 0 0 3px 0 !important;
  }
`;

const Link = styled.a`
  position: relative;
  &:hover {
    text-decoration: underline;
  }
  &.active {
    color: var(--color-success);
    &:before {
      font-family: Arial;
      display: block;
      content: "▶";
      position: absolute;
      left: -1.5rem;
    }
    @media (max-width: 992px) {
    }
  }
`;

const MenuLinks = styled.div`
  @media (max-width: 992px) {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    text-align: center;
    background: linear-gradient(45deg, #2d003c, #160122);
    margin: 0;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(105%);
    transition: 0.3s ease-in;
    padding-left: 0;
    z-index: 1;
    &.show-nav {
      transform: translateX(0%);
    }
    @media (min-width: 480px) {
      span.pipe {
        display: block;
      }
    }
  }
`;

const WhitepaperNav = () => {
  const [isOpen, setNav] = useState(false);
  const toggleNav = () => {
    setNav((isOpen) => !isOpen);
  };
  return (
    <Section>
      <MenuLinks className={isOpen ? "nav-links show-nav" : "nav-links"}>
        <h2>Whitepaper</h2>
        <List>
          <ListItem>
            <Link href="#note" onClick={toggleNav}>
              Note from team
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#introduction" onClick={toggleNav}>
              Introduction
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#what" onClick={toggleNav}>
              What are the Pocket Godz?
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#overview" onClick={toggleNav}>
              Pocket Godz Fighter Game Overview
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#p2e" onClick={toggleNav}>
              P2E Pain Points
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#ethos" onClick={toggleNav}>
              Pocket Godz Ethos
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#technical" onClick={toggleNav}>
              Technical info
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#gameplay" onClick={toggleNav}>
              Gameplay
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#modes" onClick={toggleNav}>
              Game modes
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#godztoken" onClick={toggleNav}>
              $GODZ Token
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#timeline" onClick={toggleNav}>
              Development Timeline
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#pdf" onClick={toggleNav}>
              Download PDF
            </Link>
          </ListItem>
        </List>
        <a href="/" internal>
          <strong>&#x3c; Back to main site</strong>
        </a>
      </MenuLinks>
      <button
        className={isOpen ? "toggle-btn toggle-btn-active" : "toggle-btn"}
        type="button"
        onClick={toggleNav}
        aria-label="Menu Button"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <PocketGod src={god} />
    </Section>
  );
};

export default WhitepaperNav;
