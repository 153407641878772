import React from "react";
import { Helmet } from "react-helmet-async";
import PocketGodAlt from "../assets/images/godz/animated/white-waiting.gif";
import TextImageSection from "./components/TextImageSection/TextImageSection";
import ReadySection from "./components/ReadySection";
import Divider from "./components/Design/Divider/Divider";
import AppShell from "./AppShell";

const TheGamePage = () => {
  return (
    <AppShell>
      <Helmet>
        <title>The Game | Pocket Godz</title>
      </Helmet>
      <TextImageSection
        image={PocketGodAlt}
        id="game"
        title="Fighter Game"
        alone
      >
        <p>
          The Pocket Godz fighter game will play similar to classic fighter
          games with WASD controls and 4-move combos.
        </p>
        <p>
          Anyone with a Solana wallet will be able to connect to the fighter
          game. Pocket Godz holders will also be able to play as the NFTs they
          own as opposed to the simple base characters that non-PGz holders must
          use. $Godz token and staking details to be announced as we get closer
          to mint.{" "}
        </p>
        <p>
          The PGz fighter game will also allow users to practice against NPC
          fighters with leaderboards to show the strongest Godz. Finally, the
          PGz fighter game will host special tournaments with 1/1 art, PGz NFTs
          and more offered as prizes! We hope to use these tournaments as a
          means to partner with other projects and communities to grow the
          Pocket Godz Fighter Game ecosystem.{" "}
        </p>
        <p>Are you ready to fight?! HIYAH</p>
      </TextImageSection>
      <Divider />
      <ReadySection />
    </AppShell>
  );
};

export default TheGamePage;
