import React from "react";
import styled from "styled-components";
import gifSingle from "../../assets/images/godz/animated/white-waiting.gif";
import gifDouble from "../../assets/images/godz/animated/black-and-white-dual-transparent.gif";
import LinkButton from "./Buttons/LinkButton";

const Section = styled.section`
  position: relative;
  @media (min-width: 992px) {
    flex-direction: row;
    padding: 100px 0 60px;
  }
  h1 {
    font-size: 5rem;
    margin-bottom: 20px;
  }
  @media (min-width: 992px) {
    padding: 40px 0 0px 0px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  @media (min-width: 992px) {
    flex-wrap: unset;
  }
`;

const LeftGrid = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  margin-top: 0;
  order: 2;
  h2 {
    margin-bottom: 25px;
    font-size: 30px;
  }

  p {
    font-size: 1.2rem;
  }

  @media (min-width: 992px) {
    flex-basis: 60%;
    max-width: 60%;
    order: unset;
  }
`;

const RightGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 1;
  img {
    max-width: 100%;
    &.desktopOnly {
      display: none;
    }
  }

  @media (min-width: 992px) {
    img {
      transform: unset;
      max-width: 150px;
      &.mobileOnly {
        display: none;
      }
      &.desktopOnly {
        display: block;
      }
    }
  }
  @media (min-width: 992px) {
    flex-basis: 40%;
    max-width: 40%;
  }

  img {
    transform: scaleX(-1);
  }
`;

const ButtonPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 1rem;
  & > * {
    width: 100%;
  }
  @media (min-width: 992px) {
    flex-wrap: nowrap;
    & > * {
      flex-grow: 1;
      width: 50%;
    }
  }
`;

const TimeAgoStyle = styled.span`
  font-family: Arial, Helvetica, sans-serif;
  color: var(--color-success);
`;

const HeroSection = () => {
  return (
    <Section id="about">
      <h1>Pocket Godz</h1>
      <Content>
        <LeftGrid>
          <h2>
            <TimeAgoStyle>SOLD OUT</TimeAgoStyle>
          </h2>
          <p className="description">
            The Pocket Godz are a collection of 3000 randomly generated fighter
            NFTs on the Solana Blockchain. Each Godz is unique and comes with
            different traits and attributes varying in rarity. Godz holders will
            be able to use their unique NFT within the PGz fighter game and will
            benefit from the Pocket Godz play-2-earn in-game economy.
          </p>
          <ButtonPanel>
            <LinkButton small href="https://play.pocketgodz.xyz/" highlighted>
              PLAY ALPHA NOW
            </LinkButton>
            <LinkButton
              inverted
              href="https://magiceden.io/marketplace/pocket_godz"
            >
              BUY ON MAGIC EDEN
            </LinkButton>
          </ButtonPanel>
        </LeftGrid>
        <RightGrid>
          <img className="desktopOnly" src={gifSingle} alt="Pocket Godz logo" />
          <img className="mobileOnly" src={gifDouble} alt="Pocket Godz logo" />
        </RightGrid>
      </Content>
    </Section>
  );
};

export default HeroSection;
