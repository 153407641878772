import React from "react";
import styled from "styled-components";

const DividerContainer = styled.div`
  margin: 50px 0;
  width: 100%;
  text-align: center;
  font-size: 3rem;
  color: var(--color-hover);
  &.mobile-only {
    @media (min-width: 480px) {
      display: none;
    }
  }
  &.sm {
    margin: 25px 0;
    font-size: 1rem;
  }
`;

const Divider = (props) => {
  let classes = [];
  if (props.mobileOnly) {
    classes.push("mobile-only");
  }
  if (props.mobileOnly) {
    classes.push("sm");
  }
  return <DividerContainer className={classes}>▀▄▀</DividerContainer>;
};

export default Divider;
