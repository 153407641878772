import React from "react";
import styled from "styled-components";

import Twitter from "../../assets/images/icons/twitter.png";
import Discord from "../../assets/images/icons/discord.png";
import Instagram from "../../assets/images/icons/instagram.png";
import TikTok from "../../assets/images/icons/tiktok.png";
import solanaLogo from "../../assets/images/icons/solana.svg";
import Divider from "./Design/Divider/Divider";

const FooterContainer = styled.footer`
  padding: 30px 50px 0;
  display: flex;
  text-align: center;

  @media (min-width: 992px) {
    text-align: left;
    padding: 80px 0 0;
  }
`;

const Container = styled.div`
  margin-bottom: 2.5rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: 992px) {
    div:nth-child(1) {
      order: 2;
      text-align: right;
    }
  }
`;

const Brand = styled.div`
  width: 100%;
  @media (min-width: 992px) {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    > * {
      flex-basis: 100%;
    }
  }

  h4 {
    margin: 16px 0;
    font-size: 24px;
    font-weight: 400;
  }

  p {
    font-size: 20px;
    margin-top: 20px;
    line-height: 26px;
  }

  .footer-menu {
    font-size: 20px;
    width: auto;
    @media (min-width: 992px) {
      padding-right: 96px;
    }
  }

  .footer-list {
    font-size: 20px;

    li {
      padding: 10px 0;
    }
  }
`;

const Bottom = styled.div`
  span {
    font-family: Arial;
  }
  display: flex;
  justify-content: center;
  a {
    font-size: 18px;
    margin-bottom: 0;
    text-decoration: none;
    margin-left: 0;
    display: flex;
    img {
      margin-left: 8px;
    }
  }
  @media (min-width: 992px) {
    justify-content: flex-start;
    align-items: center;
  }
`;

const SocialIcons = styled.div`
  a {
    display: inline-block;
    &:last-of-type img {
      margin-right: 0;
    }
  }
  img {
    width: 40px;
    margin-right: 16px;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Container>
        <Brand>
          <h4>Socials</h4>
          <SocialIcons>
            <a
              href="https://discord.gg/bN2KzaTNeS"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Discord} alt="Discord icon" />
            </a>
            <a
              href="https://twitter.com/pocketgodz"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Twitter} alt="Twitter icon" />
            </a>
            <a
              href="https://www.instagram.com/pocketgodz/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Instagram} alt="Instagram icon" />
            </a>
            <a
              href="https://vm.tiktok.com/ZSe4guQUf/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={TikTok} alt="TikTok icon" />
            </a>
          </SocialIcons>
        </Brand>
        <Divider small mobileOnly />
        <Brand>
          <h4>&copy; 2022 Pocket Godz</h4>
          <Bottom>
            <span>Built with love on</span>
            <a href="https://solana.com/" target="_blank" rel="noreferrer">
              <img src={solanaLogo} alt="solana icon" style={{ width: 24 }} />
            </a>
          </Bottom>
        </Brand>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
