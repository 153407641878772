import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import AppShell from "./AppShell";

const Section = styled.section`
  padding: 80px 0 50px;

  h2 {
    text-align: center;
    font-size: 48px;
    margin-bottom: 64px;
  }

  @media (min-width: 480px) {
    h2 {
      font-size: 64px;
    }
  }

  @media (min-width: 992px) {
    padding: 80px 50px 0 50px;
    h2 {
      font-size: 64px;
    }
  }
`;

const FaqItem = styled.div`
  .sidebar-nav-menu-item {
    display: block;
    margin-bottom: 1rem;
  }

  .question {
    font-size: 24px;
    color: #e2ece9;
    letter-spacing: 0.1em;
  }

  .sidebar-nav-menu-item-head {
    cursor: pointer;
    padding: 10px;
    padding-right: 7%;
    position: relative;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 3px solid #e2ece9;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.05);
    position: relative;
    &:after {
      content: "+";
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: 2%;
      font-size: 2rem;
    }
  }

  .sidebar-nav-menu-item-body {
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: 0.5s;
    font-family: "Archivo", sans-serif;
    font-size: 20px;
    letter-spacing: 0.06em;
    line-height: 1.4em;
  }

  .sidebar-nav-menu-item.item-active {
    .sidebar-nav-menu-item-head {
      &:after {
        content: "-";
      }
    }

    .sidebar-nav-menu-item-body {
      height: auto;
      opacity: 1;
      padding: 1rem;
      color: #e2ece9;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      border-bottom: 3px solid #e2ece9;
      border-right: 3px solid #e2ece9;
      border-left: 3px solid #e2ece9;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
`;

const FAQ = () => {
  const [activeCollapse, setActiveCollapse] = useState("");

  const handleExpandCollapse = (name) => {
    if (activeCollapse === name) {
      setActiveCollapse("");
    } else {
      setActiveCollapse(name);
    }
  };

  return (
    <AppShell>
      <Helmet>
        <title>FAQ | Pocket Godz</title>
      </Helmet>
      <Section id="faq">
        <h2>FAQ</h2>
        <FaqItem className="sidebar-nav" key={`faq-1`}>
          <div className="sidebar-nav-menu">
            <div
              className={`sidebar-nav-menu-item ${
                activeCollapse === 1 ? "item-active" : ""
              }`}
              onClick={() => handleExpandCollapse(1)}
            >
              <div className="sidebar-nav-menu-item-head">
                <h4 className="question">What are Pocket Godz?</h4>
              </div>
              <div className="sidebar-nav-menu-item-body">
                <p>
                  The Pocket Godz are a collection of 3000 randomly generated
                  fighter NFTs on the Solana Blockchain. Each Godz is unique and
                  comes with different traits and attributes varying in rarity.
                  Godz holders will be able to use their unique NFT within the
                  PGz fighter game and will benefit from the Pocket Godz
                  play-2-earn in-game economy.
                </p>
              </div>
            </div>
          </div>
        </FaqItem>
        <FaqItem className="sidebar-nav" key={`faq-2`}>
          {/*ADD 1 TO NUMBER */}
          <div className="sidebar-nav-menu">
            <div
              className={`sidebar-nav-menu-item ${
                activeCollapse === 2 ? "item-active" : "" // ADD 1 TO NUMBER
              }`}
              onClick={() => handleExpandCollapse(2)} // ADD 1 TO NUMBER
            >
              <div className="sidebar-nav-menu-item-head">
                <h4 className="question">Wen mint?</h4>
              </div>
              <div className="sidebar-nav-menu-item-body">
                <p>
                  <strong>Date</strong>: 16 Feb 2022
                  <br />
                  <strong>Time</strong>: Whitelist mint 21:30-22:00 UTC. Public
                  mint 22:00 UTC. Only one NFT can be minted per wallet during
                  Whitelist mint.
                  <br />
                  <strong>Total Supply:</strong> 3000
                  <br />
                  <strong>Reserved:</strong> 15 &mdash; marketing &amp; the PGz
                  fighter game economy ecosystem
                  <br />
                  <strong>Cost:</strong> 1 SOL
                </p>
              </div>
            </div>
          </div>
        </FaqItem>
        <FaqItem className="sidebar-nav" key={`faq-3`}>
          {/*ADD 1 TO NUMBER */}
          <div className="sidebar-nav-menu">
            <div
              className={`sidebar-nav-menu-item ${
                activeCollapse === 3 ? "item-active" : "" // ADD 1 TO NUMBER
              }`}
              onClick={() => handleExpandCollapse(3)} // ADD 1 TO NUMBER
            >
              <div className="sidebar-nav-menu-item-head">
                <h4 className="question">How will the game work?</h4>
              </div>
              <div className="sidebar-nav-menu-item-body">
                <p>
                  For an initial overview of the Pocket Godz Fighter game, check
                  out the link below. In the coming weeks a more thorough
                  whitepaper outlining the staking mechanics, tokenomics, game
                  features &amp; development timelines will be released.
                  <a href="/whitepaper">You can read out whitepaper here.</a>
                </p>
              </div>
            </div>
          </div>
        </FaqItem>
        <FaqItem className="sidebar-nav" key={`faq-4`}>
          {/*ADD 1 TO NUMBER */}
          <div className="sidebar-nav-menu">
            <div
              className={`sidebar-nav-menu-item ${
                activeCollapse === 4 ? "item-active" : "" // ADD 1 TO NUMBER
              }`}
              onClick={() => handleExpandCollapse(4)} // ADD 1 TO NUMBER
            >
              <div className="sidebar-nav-menu-item-head">
                <h4 className="question">
                  When will the Pocket Godz Fighter Game be available?
                </h4>
              </div>
              <div className="sidebar-nav-menu-item-body">
                <p>
                  We are extremely excited to be partnered with White Elephant
                  Finance to develop the Pocket Godz Fighter Game and in-game
                  economy. The first portion of the game will be the Player v.
                  Computer component and will be released for beta testing
                  within the community in late Q1 / early Q2 2022.
                </p>
              </div>
            </div>
          </div>
        </FaqItem>
        <FaqItem className="sidebar-nav" key={`faq-5`}>
          {/*ADD 1 TO NUMBER */}
          <div className="sidebar-nav-menu">
            <div
              className={`sidebar-nav-menu-item ${
                activeCollapse === 5 ? "item-active" : "" // ADD 1 TO NUMBER
              }`}
              onClick={() => handleExpandCollapse(5)} // ADD 1 TO NUMBER
            >
              <div className="sidebar-nav-menu-item-head">
                <h4 className="question">Why are you posting about 1/1 Art?</h4>
              </div>
              <div className="sidebar-nav-menu-item-body">
                <p>
                  <a
                    href="https://twitter.com/PocketGodz/status/1483945959059255299?s=20"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Check out the thread
                  </a>{" "}
                  for a primer on the Pocket Godz commitment to 1/1 Art on
                  Solana. You can also go to the Featured Art channel to learn
                  about different artists and pieces in the Pocket Godz
                  community fund!
                </p>
              </div>
            </div>
          </div>
        </FaqItem>
      </Section>
    </AppShell>
  );
};

export default FAQ;
